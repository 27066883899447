<template>
    <div class="registerAccount_container" id="open_account" ref="open_account">
        <div class="registerAccount_wrap">
            <div class="registerAccount_content">
                <div class="title_content">
                    <div class="title">
                        {{ $t("langConfig.openAccount.openAccountTitle") }}
                    </div>
                    <div class="underline_content">
                        <div class="underline" :style="icon_underline"></div>
                    </div>
                </div>
                <div class="description_content">
                    <div class="description">
                        <span>{{
                            $t("langConfig.openAccount.openAccountProcess")
                        }}</span>
                    </div>
                </div>
                <div class="scroll_content">
                    <vue-scroll :ops="opsConfig">
                        <div class="icon_content">
                            <div class="download_content">
                                <div
                                    class="download_icon"
                                    :style="icon_download"
                                ></div>
                                <div class="download_description">
                                    <div class="description">
                                        <span>{{
                                            $t(
                                                "langConfig.openAccount.download"
                                            )
                                        }}</span>
                                    </div>
                                </div>
                                <!--
                            <div class="businessAccount_icon" >
                                <div class="business"  @click="onclickBusiness" >
                                    <span>Business Account</span>
                                </div>
                            </div>
                            <div class="personalAccount_icon">
                                <div class="personal"   @click="onclickPersonal"><span>Personal Account</span></div>
                            </div>
                            --></div>

                            <div class="arrow_content">
                                <div class="arrow" :style="icon_arrow"></div>
                            </div>

                            <div class="submit_content">
                                <div class="submit_icon">
                                    <div
                                        class="icon"
                                        :style="icon_submit"
                                    ></div>
                                </div>
                                <div class="submit_description">
                                    <div class="description">
                                        <span>{{
                                            $t("langConfig.openAccount.submit")
                                        }}</span>
                                    </div>
                                </div>
                                <!--
                            <div class="submitEmail_icon">
                                <div class="email_content">
                                    <div class="email_icon"><div class="icon" :style="icon_email"></div></div>
                                    <div class="email_description"><div class="description"><span>Submit Email</span></div></div>
                                </div>
                                <div class="copy_content" >
                                    <div class="copy_description"><div class="description"><p>info@asiadb.com</p></div></div>
                                    <div class="copy_icon">
                                        <div class="icon":style="icon_copy">
                                            <button class="tag-read" data-clipboard-text="info@asiadb.com" @click="onclickCopyState"></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="copyState_content" v-show="isShowCopyState">
                                <div class="copyState_icon"><div class="icon":style="icon_copyState"></div></div>
                                <div class="copyState_description"><div class="description"><span>Text Copied!</span></div></div>
                            </div>>

                            --></div>

                            <div class="arrow_content left_arrow_content">
                                <div class="arrow" :style="icon_arrow"></div>
                            </div>

                            <div class="video_content">
                                <div class="video_icon">
                                    <div class="icon" :style="icon_video"></div>
                                </div>
                                <div class="video_description">
                                    <div class="description">
                                        <span>{{
                                            $t(
                                                "langConfig.openAccount.videoVert"
                                            )
                                        }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="arrow_content right_arrow_content">
                                <div class="arrow" :style="icon_arrow"></div>
                            </div>

                            <div class="checkPending_content">
                                <div class="checkPending_icon">
                                    <div class="icon" :style="icon_check"></div>
                                </div>
                                <div class="checkPending_description">
                                    <div class="description">
                                        <span>{{
                                            $t(
                                                "langConfig.openAccount.checkEmail"
                                            )
                                        }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="function_content">
                            <div class="button_content">
                                <div class="businessAccount_icon">
                                    <a class="business" :href="env.VUE_APP_BUSACCOUNTPDF" target="_blank">
                                        {{ $t( "langConfig.openAccount.businessAccount" ) }}
                                    </a>
                                </div>
                                <div class="personalAccount_icon">
                                    <a class="personal" :href="env.VUE_APP_PERACCOUNTPDF" target="_blank">
                                        {{ $t( "langConfig.openAccount.personalAccount" ) }}
                                    </a>
                                </div>
                            </div>
                            <div class="submit_copy_content">
                                <div class="submitEmail_icon">
                                    <div class="email_content">
                                        <div class="email_icon">
                                            <div
                                                class="icon"
                                                :style="icon_email"
                                            ></div>
                                        </div>
                                        <div class="email_description">
                                            <div class="description">
                                                <span>{{
                                                    $t(
                                                        "langConfig.openAccount.submitEmail"
                                                    )
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="copy_content">
                                        <div class="copy_description">
                                            <div class="description">
                                                <p>info@asiadb.com</p>
                                            </div>
                                        </div>
                                        <div class="copy_icon">
                                            <div
                                                class="icon"
                                                :style="icon_copy"
                                            >
                                                <button
                                                    class="tag-read"
                                                    data-clipboard-text="info@asiadb.com"
                                                    @click="onclickCopyState"
                                                ></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="copyState_content"
                                    v-show="isShowCopyState"
                                >
                                    <div class="copyState_icon">
                                        <div
                                            class="icon"
                                            :style="icon_copyState"
                                        ></div>
                                    </div>
                                    <div class="copyState_description">
                                        <div class="description">
                                            <span>{{
                                                $t(
                                                    "langConfig.openAccount.copyText"
                                                )
                                            }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </vue-scroll>
                </div>
                <div class="tip_wrap">
                    <span class="tip_icon">*</span>
                    <span class="tip">{{$t("langConfig.openAccount.tip")}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Clipboard from "clipboard";
export default {
    name: "RegisterAccount",
    data() {
        return {
            env: this.$env,
            isShowCopyState: false,
            isSelectedBusiness: false,
            isSelectedPersonal: false,
            icon_download: {
                backgroundImage:
                    "url(" +
                    require("../assets/homePicture/RegistAccount/high_pixel_download.png") +
                    ")",
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%",
            },
            icon_submit: {
                backgroundImage:
                    "url(" +
                    require("../assets/homePicture/RegistAccount/high_pixel_submit.png") +
                    ")",
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%",
            },
            icon_video: {
                backgroundImage:
                    "url(" +
                    require("../assets/homePicture/RegistAccount/high_video.png") +
                    ")",
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%",
            },
            icon_check: {
                backgroundImage:
                    "url(" +
                    require("../assets/homePicture/RegistAccount/high_pixel_check.png") +
                    ")",
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%",
            },
            icon_email: {
                backgroundImage:
                    "url(" +
                    require("../assets/homePicture/RegistAccount/high_pixel_mail.png") +
                    ")",
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%",
            },
            icon_copy: {
                backgroundImage:
                    "url(" +
                    require("../assets/homePicture/RegistAccount/high_pixel_copy.png") +
                    ")",
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%",
            },
            icon_copyState: {
                backgroundImage:
                    "url(" +
                    require("../assets/homePicture/RegistAccount/high_pixel_copyState.png") +
                    ")",
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%",
            },
            icon_underline: {
                backgroundImage:
                    "url(" +
                    require("../assets/homePicture/Underline.png") +
                    ")",
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%",
            },
            icon_arrow: {
                backgroundImage:
                    "url(" +
                    require("../assets/homePicture/RegistAccount/arrow.png") +
                    ")",
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%",
            },
            opsConfig: {
                rail: {
                    background: "rgba(21, 33, 71, 1)", //轨道的背景色。
                    opacity: 1, //轨道的透明度。 0是透明，1是不透明
                    size: "10px", //轨道的尺寸。
                    specifyBorderRadius: false, //是否指定轨道的 borderRadius， 如果不那么将会自动设置。
                    gutterOfEnds: null,
                    gutterOfSide: "0px", //距离容器的距离
                    keepShow: false, //是否即使 bar 不存在的情况下也保持显示。
                },
                bar: {
                    keepShow: true, //滚动条是否保持显示。
                    size: "6px", //bar的尺寸
                    minSize: 0.1, //bar的最小尺寸
                    background: "rgba(73, 118, 244, 1)", //滚动条背景色
                    opacity: 0.4,
                },
                vuescroll: {
                    wheelScrollDuration: 0,
                    wheelDirectionReverse: false,
                    locking: true,
                    checkShifKey: true,
                },
            },
        };
    },
    methods: {
        onclickCopyState() {
            const clipboard = new Clipboard(".tag-read");
            clipboard.on("success", (e) => {
                // 释放内存
                clipboard.destroy();
                this.isShowCopyState = true;
                setTimeout(() => {
                    this.isShowCopyState = false;
                }, 3000);
            });
            clipboard.on("error", (e) => {
                // 释放内存
                clipboard.destroy();
            });
        },
        pageScroll() {
            if (
                document.getElementById("open_account").getBoundingClientRect()
                    .top <
                window.innerHeight / 2
            ) {
                this.$store.commit("currentIndex", 1);
            }
        },
    },
    mounted() {
        window.addEventListener("scroll", this.pageScroll, true);
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.pageScroll, true);
    },
};
</script>

<style scoped lang="less">
.registerAccount_container {
    width: 100%;
    background: #0d1734;
    .registerAccount_wrap {
        margin: 0 auto;
        max-width: 1200px;
        .registerAccount_content {
            box-sizing: border-box;
            padding: 80px 0;
            max-width: 1000px;
            min-height: 696px;
            margin: 0 auto;
            background: #0d1734;
            @media (max-width: 1050px) {
                padding: 80px 48px;
            }
            @media (max-width: 400px) {
                padding: 80px 16px;
            }
            .title_content {
                .title {
                    font-size: 24px;
                    font-weight: 600;
                    color: #ffffff;
                    line-height: 24px;
                }
                .underline_content {
                    margin-top: 14px;
                    .underline {
                        width: 92px;
                        height: 15px;
                    }
                }
            }

            .description_content {
                padding-top: 24px;
                .description {
                    font-size: 16px;
                    font-weight: 400;
                    color: rgba(255,255,255,0.5);
                    line-height: 24px;
                }
            }
            .scroll_content {
                display: flex;
                flex-direction: column;
                margin-top: 0.6rem;
                ::v-deep .__panel {
					&::-webkit-scrollbar {
                        display: none;
                    }
				}
                ::v-deep .__rail-is-horizontal {
                    top: 3.5rem;
                }
                .icon_content {
                    display: flex;
                    justify-content: center;
                    .arrow_content {
                        margin-top: 55px;
                        .arrow {
                            width: 156px;
                            height: 15px;
                        }
                    }
                    .left_arrow_content {
                        margin-right: 0px;
                        margin-left: 0;
                    }
                    .right_arrow_content {
                        margin-right: 0px;
                        margin-left: 0px;
                        @media (max-width: 1100px) {
                            margin-right: 25px;
                        }
                    }
                    .download_content {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        @media (max-width: 1100px) {
                            width: 200px;
                        }
                        .download_icon {
                            width: 120px;
                            height: 120px;
                        }
                        .download_description {
                            .description {
                                height: 16px;
                                font-size: 16px;
                                font-weight: 400;
                                color: #ffffff;
                                line-height: 16px;
                                padding-top: 24px;
                                padding-bottom: 32px;
                            }
                        }
                    }

                    .submit_content {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        @media (max-width: 1100px) {
                            width: 200px;
                        }
                        .submit_icon {
                            .icon {
                                width: 120px;
                                height: 120px;
                            }
                        }

                        .submit_description {
                            .description {
                                height: 16px;
                                font-size: 16px;
                                font-weight: 400;
                                color: #ffffff;
                                line-height: 16px;
                                padding-top: 24px;
                                padding-bottom: 32px;
                            }
                        }
                    }
                    .video_content {
                        @media (max-width: 1100px) {
                            width: 200px;
                        }
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        .video_icon {
                            .icon {
                                width: 106px;
                                height: 120px;
                            }
                        }
                        .video_description {
                            .description {
                                padding-bottom: 100px;
                                height: 16px;
                                font-size: 16px;
                                font-weight: 400;
                                color: #ffffff;
                                line-height: 16px;
                                padding-top: 24px;
                                padding-bottom: 32px;
                            }
                        }
                    }
                    .checkPending_content {
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        .checkPending_icon {
                            .icon {
                                width: 120px;
                                height: 120px;
                            }
                        }

                        .checkPending_description {
                            .description {
                                padding-bottom: 100px;
                                height: 16px;
                                font-size: 16px;
                                font-weight: 400;
                                color: #ffffff;
                                line-height: 16px;
                                padding-top: 24px;
                                padding-bottom: 32px;
                            }
                        }
                    }
                }
                .function_content {
                    height: 170px;
                    display: flex;
                    position: relative;
                    .button_content {
                        // left: 100px;
                        @media (max-width: 1100px) {
                            left: 20px;
                        }
                        display: flex;
                        flex-direction: column;
                        position: absolute;
                        .businessAccount_icon {
                            position: relative;
                            margin-top: 24px;
                            .business {
                                width: 170px;
                                height: 40px;
                                font-weight: 400;
                                color: #ffffff;
                                line-height: 16px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                cursor: pointer;
                                background: url("../assets/homePicture/RegistAccount/high_pixel_button_notclick.png")
                                    no-repeat center / cover;

                                &:hover {
                                    width: 170px;
                                    height: 40px;
                                    font-weight: 400;
                                    color: #ffffff;
                                    line-height: 16px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    cursor: pointer;
                                    background: url("../assets/homePicture/RegistAccount/high_pixel_button_click.png")
                                        no-repeat center / cover;
                                }
                            }
                        }
                        .personalAccount_icon {
                            margin-top: 12px;
                            .personal {
                                width: 170px;
                                height: 40px;
                                font-weight: 400;
                                color: #ffffff;
                                line-height: 16px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                cursor: pointer;
                                background: url("../assets/homePicture/RegistAccount/high_pixel_button_notclick.png")
                                    no-repeat center / cover;

                                &:hover {
                                    background: url("../assets/homePicture/RegistAccount/high_pixel_button_click.png")
                                        no-repeat center / cover;
                                }
                            }
                        }
                    }
                    .submit_copy_content {
                        left: 245px;
                        @media (max-width: 1100px) {
                            left: 340px;
                        }
                        position: absolute;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        .submitEmail_icon {
                            margin-top: 24px;
                            width: 224px;
                            height: 84px;
                            background: rgba(73, 118, 244, 0.07);
                            border-radius: 8px;
                            border: 1px solid rgba(73, 118, 244, 0.15);
                            display: flex;
                            flex-direction: column;
                            align-items: center;

                            .email_content {
                                display: flex;
                                margin-top: 16px;

                                .email_icon {
                                    .icon {
                                        width: 24.04px;
                                        height: 19.04px;
                                        font-weight: 400;
                                        color: #ffffff;
                                        line-height: 16px;
                                        opacity: 1;
                                    }
                                }

                                .email_description {
                                    margin-left: 8px;

                                    .description {
                                    }
                                }
                            }

                            .copy_content {
                                display: flex;
                                margin-top: 12px;
                                justify-content: center;
                                align-items: center;

                                .copy_description {
                                    .description {
                                        height: 16px;
                                        font-size: 16px;
                                        font-weight: 600;
                                        color: #ffffff;
                                        line-height: 16px;
                                    }
                                }

                                .copy_icon {
                                    position: relative;
                                    margin-left: 8px;

                                    .icon {
                                        width: 14px;
                                        height: 16px;

                                        .tag-read {
                                            width: 30px;
                                            height: 30px;
                                            opacity: 0.01;
                                            cursor: pointer;
                                        }
                                    }
                                }
                            }
                        }
                        .copyState_content {
                            margin-top: 120px;
                            display: flex;
                            width: 200px;
                            height: 40px;
                            background: #19254f;
                            border-radius: 8px;
                            align-items: center;
                            justify-content: center;
                            position: absolute;
                            .copyState_icon {
                                .icon {
                                    width: 20px;
                                    height: 20px;
                                }
                            }
                            .copyState_description {
                                margin-left: 16px;
                                .description {
                                    width: 108px;
                                    height: 16px;
                                    font-size: 16px;
                                    font-weight: 400;
                                    color: #ffffff;
                                    line-height: 16px;
                                }
                            }
                        }
                    }
                }
            }
            .tip_wrap {
                margin-top: 0.3rem;
                .tip_icon {
                    color: #4976F4;
                }
                .tip {
                    margin-left: 0.04rem;
                    font-size: 0.14rem;
                    line-height: 0.2rem;
                    color: rgba(255, 255, 255, 0.35);
                }
            }
        }
    }
}
</style>