<template>
	<div class="introduce_container" id="introduction" ref="introduction">
		<div class="introduce_wrap">
			<div class="introduce_content">
				<div class="robot">
					<img src="../assets/homePicture/robot/shouping_banner.png" alt="">
				</div>
				<div class="description">
					<div class="title">
						<div class="logo_container">
							<img src="../assets/homePicture/introduce/logo_white.png" alt="">
						</div>
						<div class="title_text">{{$t("langConfig.introTitle")}}</div>
					</div>
					<div class="content">
						<div class="first_paragraph">{{$t("langConfig.introDescriptionF")}}</div>
						<div class="second_paragraph">{{$t("langConfig.introDescriptionS")}}</div>
						<div class="second_paragraph">{{$t("langConfig.introDescriptionT")}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Introduce",
    methods: {
        pageScroll() {
            if(document.getElementById("introduction").getBoundingClientRect().top <= window.innerHeight / 2) {
                this.$store.commit("currentIndex", 0);
            }
        }
    },
    mounted() {
		window.addEventListener("scroll", this.pageScroll, true);
	},
    beforeDestroy() {
		window.removeEventListener("scroll", this.pageScroll, true);
    }
}
</script>

<style scoped lang="less">
.introduce_container {
    box-sizing: border-box;
	width: 100%;
	min-height: 6.96rem;
	background: url("../assets/homePicture/introduce/high_pixel_introduce.png") no-repeat center / cover;
	.introduce_wrap {
		margin: 0 auto;
		max-width: 12rem;
		height: 100%;
		.introduce_content {
			display: flex;
			align-items: center;
			box-sizing: border-box;
			margin: 0 auto;
			padding: 1.3rem 0.18rem;
			max-width: 11.64rem;
			@media (max-width: 932px) {
				flex-direction: column;
			}
			.robot {
				height: 5.27rem;
				@media (max-width: 932px) {
					height: 4rem;
				}
				@media (max-width: 430px) {
					height: 2.4rem;
				}
				img {
					height: 100%;
					vertical-align: center;
				}
			}
			.description {
				margin-left: 0.53rem;
				@media (max-width: 932px) {
					margin-left: 0;
				}
				.title {
					display: flex;
					align-items: center;
					@media (max-width: 932px) {
						justify-content: center;
						margin-top: 0.44rem;
					}
					.logo_container {
						width: 0.6rem;
						height: 0.6rem;
						@media (max-width: 430px) {
							width: 0.48rem;
							height: 0.48rem;
						}
						img {
							width: 100%;
							vertical-align: center;
						}
					}
					.title_text {
						margin-left: 0.24rem;
						font-size: 0.4rem;
						font-weight: 600;
						line-height: 0.4rem;
						@media (max-width: 400px) {
							font-size: 0.28rem;
							line-height: 0.32rem;
						}
					}
				}
				.content {
					margin-top: 0.24rem;
					max-width: 5.05rem;
					font-size: 0.14rem;
					font-weight: 600;
					color: rgba(255,255,255,0.69);
					line-height: 0.2rem;
					.second_paragraph {
						margin-top: 0.28rem;
					}
				}
			}
		}
	}
}
</style>