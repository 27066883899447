const env = process.env;
export const langConfig = {
    lang: "en-US",
    navList: [
        {
            navText: "Introduction",
            id: "introduction"
        },
        {
            navText: "Open Account",
            id: "open_account"
        },
        {
            navText: "Download App",
            id: "download_app"
        },
        {
            navText: "Upload",
            link: `${env.VUE_APP_UPLOADURL}?lang=en-US`
        },
        {
            navText: "Contact US",
            id: "contact_us"
        },
        {
            navText: "Support",
            link: `${env.VUE_APP_SUPPORTURL}/en-US/`
        },
    ],
    introTitle: "A-Bank",
    introDescriptionF: "The A-Bank Super Wallet App, provided by Asia Digital Bank (licensed Labuan Investment Bank, registration number: LL.16434), is a crossover super wallet that integrates traditional financial service modes with digital financial service concepts. It is committed to providing efficient, legal, safe, compliant and convenient innovative digital financial services for individual and corporate users.",
    introDescriptionS: "The A-Bank Super Wallet App provides fast and efficient cross-border payment solutions for cross-border goods trade, service trade and personal international payment through the flexible use of advanced FinTech means.",
    introDescriptionT: "At present, a total of 17 fiat currencies are supported, which include SGD, USD, EUR, CNH and HKD, as well as AsiaDB Stablecoin ACNH and many other digital assets for users to choose flexibly.",
    openAccount: {
        openAccountTitle: "Open Account",
        openAccountProcess: "Account Opening Process",
        download: "Download",
        submit: "Submit",
        videoVert: "Video Verification",
        checkEmail: "Check Email",
        businessAccount: "Business Account",
        personalAccount: "Personal Account",
        submitEmail: "Submit Email",
        copyText: "Text Copied!",
        tip: "Individual Account Opening is currently unavailable to person who is psychically residing in the Mainland China; Account Opening for cross-border trading companies which need to do clearing and settlement with RMB is available."
    },
    downloadApp: {
        downloadAppTitle: "Download App",
        downloadAppSubTitle: "The A-Bank APP is for Asia Digital Bank customers only. Make sure you have already opened a bank account with Asia Digital Bank.",
        scanQrCode: "Scan this code to download",
        androidAPKDownload: "Android APK download",
        androidAPK: "Android APK",
    },
    downloadAppList: [
        {
            iconName:'icon-Android',
            description:'Download',
            apk: "Android APK",
            link: `${env.VUE_APP_ANDROIDURL}`
        },
        {
            iconName:'icon-apple',
            description:'Download from',
            apk: "App Store",
            link: `${env.VUE_APP_APPSTOREURL}`
        },
        {
            iconName:'icon-google',
            description:'Get it from',
            apk: "Google Play",
            link: `${env.VUE_APP_GOOGLEPLAYURL}`
        },
        {
            iconName:'icon-HUAWEI',
            description:'Download from',
            apk: "App Gallery",
            link: `${env.VUE_APP_HUAWEI_APPSTOREURL}`
        },
    ],
    contactUs: {
        contactUsTitle: "Contact Us",
        contactUsSubTitle: "If you encounter problems with the application, please contact customer service.",
        email: "Email",
        tel: "Tel",
    },
    copyright: `Copyright © ${new Date().getFullYear()} A-Bank. All Rights Reserved.`,
    protocolList: [
        {
            protocolName: "Privacy Policy",
            tip: "privacy_policy_en.html"
        },
        {
            protocolName: "Agreement",
            tip: "user_agreement_en.html"
        },
        // {
        //     protocolName: "Subscription Plan",
        //     tip: "membership_agreement_en.html"
        // },
    ],
    announcement:'A-Bank Super Wallet Introduction'
}