<template>
	<div class="contactus_container" id="contact_us" ref="contact_us">
		<div class="contactus_content_container">
			<div class="contactus_content">
				<div class="title">{{$t("langConfig.contactUs.contactUsTitle")}}</div>
				<p class="contact_text">{{$t("langConfig.contactUs.contactUsSubTitle")}}</p>
				<ul class="chat_info_list">
					<li class="chat_item" v-for="(item,index) in chatList" :key="index">
						<i class="iconfont" :class="item.icon"></i>
						<div class="item_wrap">
							<span class="item_text">{{item.text}}</span>
							<span class="item_info">{{item.info}}</span>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "contactUs",
    computed: {
        chatList() {
            return [
				{
					icon: "icon-mail",
					text: this.$t("langConfig.contactUs.email"),
					info: "info@asiadb.com"
				},
				{
					icon: "icon-Tel",
					text: this.$t("langConfig.contactUs.tel"),
					info: "＋6012-509 9224"
				},
			]
        }
    },
    methods: {
        pageScroll() {
            if(document.getElementById("download_app").getBoundingClientRect().top < -20) {
                this.$store.commit("currentIndex", 4);
            }
        }
    },
    mounted() {
		window.addEventListener("scroll", this.pageScroll, true);
	},
    beforeDestroy() {
		window.removeEventListener("scroll", this.pageScroll, true);
    }
}
</script>

<style scoped lang="less">
.contactus_container {
	width: 100%;
	background: url("../assets/homePicture/contactUs/chatus_bg.png") no-repeat center / cover;
	.contactus_content_container {
		box-sizing: border-box;
		margin: 0 auto;
		padding: 0.8rem 0;
		max-width: 12rem;
		@media (max-width: 1050px) {
			padding: 0.8rem 0.48rem;
		}
		@media (max-width: 400px) {
			padding: 0.4rem 0.16rem;
		}
		.contactus_content {
			margin: 0 auto;
			max-width: 10rem;
			.title {
				display: flex;
				flex-direction: column;
				font-size: 0.24rem;
				font-weight: 600;
				color: #FFFFFF;
				line-height: 0.24rem;
				&::after {
					content: "";
					display: block;
					margin-top: 0.12rem;
					width: 1rem;
					height: 0.11rem;
					background: url("../assets/homePicture/line.png") no-repeat center / cover;
				}
			}
			.contact_text {
				margin-top: 0.24rem;
				font-size: 0.14rem;
				font-weight: 400;
				color: rgba(255,255,255,0.69);
				line-height: 0.24rem;
			}
			.chat_info_list {
				box-sizing: border-box;
				display: flex;
				align-items: center;
				margin-top: 0.24rem;
				padding: 0.12rem 0.16rem;
				max-width: 5.1rem;
				background: #122046;
				border-radius: 0.08rem;
				@media (max-width: 600px) {
					flex-direction: column;
					align-items: flex-start;
					max-width: 2.9rem;
				}
				.chat_item {
					display: flex;
					align-items: center;
					margin-left: 0.48rem;
					@media (max-width: 600px) {
						margin-left: 0;
						margin-top: 0.12rem;
					}
					&:first-child {
						margin-left: 0;
						@media (max-width: 600px) {
							margin-top: 0rem;
						}
						.iconfont {
							font-size: 0.18rem;
						}
					}
					.iconfont {
						font-size: 0.24rem;
						color: #4976F4;
					}
					.item_wrap {
						display: flex;
						align-items: center;
						margin-left: 0.09rem;
						.item_text {
							font-size: 0.14rem;
							font-weight: 400;
							color: rgba(255,255,255,0.69);
							line-height: 0.14rem;
						}
						.item_info {
							margin-left: 0.16rem;
							font-size: 0.14rem;
							font-weight: bold;
							color: #FFFFFF;
							line-height: 0.14rem;
						}
					}
				}
			}
		}
	}
}
</style>