const env = process.env;
export const langConfig = {
    lang: "zh-CN",
    navList: [
        {
            navText: "介绍",
            id: "introduction"
        },
        {
            navText: "开户",
            id: "open_account"
        },
        {
            navText: "下载应用",
            id: "download_app"
        },
        {
            navText: "上传",
            link: `${env.VUE_APP_UPLOADURL}?lang=zh-CN`
        },
        {
            navText: "联系我们",
            id: "contact_us"
        },
        {
            navText: "帮助",
            link: `${env.VUE_APP_SUPPORTURL}/zh-CN/`
        },
    ],
    introTitle: "A-Bank",
    introDescriptionF: "A-Bank 应用是由亚洲数字银行（Asia Digital Bank，持牌纳闽投资银行，注册号：LL.16434）提供的，是融合传统金融服务模式与数字金融服务理念的跨界超级钱包，致力于为个人和企业用户提供高效、合法、安全、合规且便捷的创新数字金融服务。",
    introDescriptionS: "A-Bank超级钱包应用通过灵活运用前沿金融科技手段为跨境贸易金融、国际结算、数字资产的发行交易、公司金融和消费金融等业务领域提供快捷高效的数字化解决方案。",
    introDescriptionT: "目前支持新加坡元、美元、欧元、人民币、港币等共計 17 种法定货币以及亚数币 ACNH 和其他多种数字资产，供用戶灵活选择。",
    openAccount: {
        openAccountTitle: "开户",
        openAccountProcess: "开户流程",
        download: "下载",
        submit: "提交",
        videoVert: "视频验证",
        checkEmail: "查收邮件",
        businessAccount: "企业开户",
        personalAccount: "个人开户",
        submitEmail: "提交邮箱",
        copyText: "已复制",
        tip: "本产品暂不支持中国大陆居民个人开户，支持国际贸易企业使用跨境人民币进行支付、收款及清结算业务。"
    },
    downloadApp: {
        downloadAppTitle: "下载应用",
        downloadAppSubTitle: "A-Bank 应用程序仅供亚数行客户登录使用，请确认您已完成开户流程。",
        scanQrCode: "扫码下载",
        androidAPKDownload: "Android APK 下载",
        androidAPK: "Android APK",
    },
    downloadAppList: [
        {
            iconName:'icon-Android',
            description:'下载客户端',
            apk: "Android APK",
            link: `${env.VUE_APP_ANDROIDURL}`
        },
        {
            iconName:'icon-apple',
            description:'下载自',
            apk: "App Store",
            link: `${env.VUE_APP_APPSTOREURL}`
        },
        {
            iconName:'icon-google',
            description:'下载自',
            apk: "Google Play",
            link: `${env.VUE_APP_GOOGLEPLAYURL}`
        },
        {
            iconName:'icon-HUAWEI',
            description:'下载自',
            apk: "App Gallery",
            link: `${env.VUE_APP_HUAWEI_APPSTOREURL}`
        },
    ],
    contactUs: {
        contactUsTitle: "联系我们",
        contactUsSubTitle: "如果您在应用或操作中遇到问题，请联系客服帮助您解决。",
        email: "邮箱",
        tel: "电话",
    },
    copyright: `版权所有 © ${new Date().getFullYear()} A-Bank`,
    protocolList: [
        {
            protocolName: "隐私政策",
            tip: "privacy_policy_zh.html"
        },
        {
            protocolName: "服务协议",
            tip: "user_agreement_zh.html"
        },
        // {
        //     protocolName: "订阅计划",
        //     tip: "membership_agreement_zh.html"
        // },
    ],
    announcement:'A-Bank 超级钱包介绍'
}