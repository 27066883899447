const env = process.env;
export const langConfig = {
  lang: 'zh-HK',
  navList: [
    {
      navText: '介紹',
      id: 'introduction',
    },
    {
      navText: '開戶',
      id: 'open_account',
    },
    {
      navText: '下載應用',
      id: 'download_app',
    },
    {
      navText: '上傳',
      link: `${env.VUE_APP_UPLOADURL}?lang=zh-HK`,
    },
    {
      navText: '聯絡我們',
      id: 'contact_us',
    },
    {
      navText: '幫助',
      link: `${env.VUE_APP_SUPPORTURL}/zh-HK/`,
    },
  ],
  introTitle: 'A-Bank',
  introDescriptionF: 'A-Bank 應用是由亞洲數字銀行（Asia Digital Bank，持牌納閩投資銀行，註冊號：LL.16434）提供的，是融合傳統金融服務模式與數字金融服務理念的跨界超級錢包，致力於為個人和企業用戶提供高效、合法、安全、合規且便捷的創新數字金融服務。',
  introDescriptionS: 'A-Bank超級錢包應用通過靈活運用前沿金融科技手段為跨境貿易金融、國際結算、數字資產的發行交易、公司金融和消費金融等業務領域提供快捷高效的數碼化解決方案。',
  introDescriptionT: '目前支持新加坡元、美元、歐元、人民幣、港幣等共計 17 種法定貨幣以及亞數幣 ACNH 和其他多種數字資產，供用戶靈活選擇。',
  openAccount: {
    openAccountTitle: '開戶',
    openAccountProcess: '開戶流程',
    download: '下載',
    submit: '提交',
    videoVert: '視頻驗證',
    checkEmail: '查收郵件',
    businessAccount: '企業開戶',
    personalAccount: '個人開戶',
    submitEmail: '提交信箱',
    copyText: '已復制',
    tip: '本產品暫不支持中國大陸居民個人開戶，支持國際貿易企業使用跨境人民幣進行支付、收款及清結算業務。',
  },
  downloadApp: {
    downloadAppTitle: '下載應用',
    downloadAppSubTitle: 'A-Bank 應用程式僅供亞數行客戶登錄使用，請確認您已完成開戶流程。',
    scanQrCode: '掃碼下載',
    androidAPKDownload: 'Android APK 下載',
    androidAPK: 'Android APK',
  },
  downloadAppList: [
    {
      iconName: 'icon-Android',
      description: '下載客戶端',
      apk: 'Android APK',
      link: `${env.VUE_APP_ANDROIDURL}`,
    },
    {
      iconName: 'icon-apple',
      description: '下載自',
      apk: 'App Store',
      link: `${env.VUE_APP_APPSTOREURL}`,
    },
    {
      iconName: 'icon-google',
      description: '下載自',
      apk: 'Google Play',
      link: `${env.VUE_APP_GOOGLEPLAYURL}`,
    },
    {
      iconName: 'icon-HUAWEI',
      description: '下載自',
      apk: 'App Gallery',
      link: `${env.VUE_APP_HUAWEI_APPSTOREURL}`,
    },
  ],
  contactUs: {
    contactUsTitle: '聯絡我們',
    contactUsSubTitle: '如果您在應用或操作中遇到問題，請聯繫客服幫助您解決。',
    email: '信箱',
    tel: '電話',
  },
  copyright: `版權所有 © ${new Date().getFullYear()} A-Bank`,
  protocolList: [
    {
      protocolName: '隱私政策',
      tip: 'privacy_policy_tc.html',
    },
    {
      protocolName: '服務協定',
      tip: 'user_agreement_tc.html',
    },
    // {
    //     protocolName: "訂閱計劃",
    //     tip: "membership_agreement_tc.html"
    // },
  ],
  announcement: 'A-Bank 超級錢包介紹',
};
