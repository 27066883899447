import Vue from 'vue';
import App from './App.vue';
import store from "./store/store";
import router from "./router/router";
import './assets/iconfont/iconfont.css';
import vuescroll from 'vuescroll';
import 'vuescroll/dist/vuescroll.css';
import VueI18n from "vue-i18n";
import scroll from 'vue-seamless-scroll'
import MarqueeText from 'vue-marquee-text-component'
Vue.use(scroll)
Vue.use(VueI18n);
Vue.prototype.$env = process.env;
Vue.config.productionTip = false;
Vue.use(vuescroll);
Vue.component('marquee-text', MarqueeText)
let locale = (location.search && location.search.split("?")[1].split("=")[1]) || localStorage.getItem("currentLang") || navigator.language || "en-US";
let i18n = new VueI18n({
    locale,
    messages: {
        "en": require('../lang/en-US'),
        "en-US": require('../lang/en-US'),
        "zh": require('../lang/zh-CN'),
        "zh-CN": require('../lang/zh-CN'),
        "hk": require('../lang/zh-HK'),
        "zh-HK": require('../lang/zh-HK'),
    }
})
new Vue({
    el: '#app',
    i18n,
    router,
    store,
    components: { App },
    template: "<App />"
})
