<template>
	<div class="downloadApp_container" id="download_app" ref="download_app">
		<div class="downloadApp_warp">
			<div class="downloadApp_content">
				<div class="title_content">
					<div class="title">
						{{ $t("langConfig.downloadApp.downloadAppTitle") }}
					</div>
					<div class="underline_content">
						<div class="underline" :style="icon_underline"></div>
					</div>
				</div>
				<div class="description_content">
					<div class="description">
                        <span>{{
		                        $t("langConfig.downloadApp.downloadAppSubTitle")
	                        }}</span>
					</div>
				</div>
				<div class="icon_content">
					<div class="code_content">
						<div class="code_title">
                            <span>{{
		                            $t("langConfig.downloadApp.scanQrCode")
	                            }}</span>
						</div>
						<div class="code_frame">
							<canvas id="qrcode"></canvas>
							<img
								src="../assets/homePicture/introduce/logo_white.png"
								alt=""
							/>
						</div>
					</div>
					<div class="download_content">
						<ul class="download_list">
							<li
								class="download_item"
								v-for="(item, index) in downloadAppList"
								:key="index"
							>
								<a :href="item.link" target="_blank" rel="noreferrer noopener">
									<div class="item_container">
										<div class="item_content">
											<span class="item_icon" :class="`iconfont ${item.iconName}`"></span>
											<div class="item_text">
												<p class="item_text_header">{{ item.description }}</p>
												<p class="item_text_content">{{item.apk}}</p>
											</div>
										</div>
									</div>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import QRCode from "qrcode";

export default {
	name: "downloadApp",
	data() {
		return {
			icon_underline: {
				backgroundImage:
					"url(" +
					require("../assets/homePicture/Underline.png") +
					")",
				backgroundRepeat: "no-repeat",
				backgroundSize: "100% 100%",
			},
			apkDownloadUrl: '',
		};
	},
	computed: {
		downloadAppList() {
			return this.$t("langConfig.downloadAppList");
		},
	},
	methods: {
		pageScroll() {
			if (
				document.getElementById("download_app").getBoundingClientRect()
					.top <
				window.innerHeight / 2
			) {
				this.$store.commit("currentIndex", 2);
			}
		},
		getQRCode() {
			let options = {
				errorCorrectionLevel: "H",
				type: "image/png",
				quality: 0.3,
				margin: 0.5,
				width: 184,
				height: 184,
				text: this.apkDownloadUrl,
				color: {
					dark: "#000",
					light: "#fff",
				},
			};
			let qrcodeWrap = document.getElementById("qrcode");
			QRCode.toCanvas(qrcodeWrap, this.apkDownloadUrl, options, (err) => {
				console.log(err);
			});
		},
	},
	mounted() {
		this.apkDownloadUrl = `${window.location.origin}/#download_app`
		this.getQRCode();
		window.addEventListener("scroll", this.pageScroll, true);
	},
	beforeDestroy() {
		window.removeEventListener("scroll", this.pageScroll, true);
	},
};
</script>

<style scoped lang="less">
.downloadApp_container {
	width: 100%;
	background: #0f1a39;

	.downloadApp_warp {
		margin: 0 auto;
		max-width: 1200px;

		.downloadApp_content {
			box-sizing: border-box;
			margin: 0 auto;
			padding: 0.8rem 0 1rem;
			max-width: 10rem;
			@media (max-width: 1050px) {
				padding-left: 0.48rem;
				padding-right: 0.48rem;
			}
			@media (max-width: 400px) {
				padding-left: 0.16rem;
				padding-right: 0.16rem;
			}

			.title_content {
				.title {
					font-size: 24px;
					font-weight: 600;
					color: #FFFFFF;
					line-height: 24px;
					@media (max-width: 1250px) {
						text-align: left;
						width: auto;
					}
				}

				.underline_content {
					margin-top: 14px;

					.underline {
						width: 92px;
						height: 15px;
					}
				}
			}

			.description_content {
				align-items: center;
				margin-top: 0.24rem;

				.description {
					max-width: 500px;
					font-size: 14px;
					font-weight: 400;
					color: rgba(255, 255, 255, 0.5);
					line-height: 24px;
				}
			}

			.icon_content {
				display: flex;
				margin-top: 0.6rem;
				@media (max-width: 768px) {
					flex-direction: column;
				}

				.code_content {
					display: flex;
					flex-direction: column;
					align-items: center;

					.code_title {
						font-size: 16px;
						font-weight: 600;
						color: #ffffff;
						line-height: 16px;
					}

					.code_frame {
						position: relative;
						margin-top: 18px;
						border-style: solid;
						border-width: 15px;
						border-color: rgba(73, 118, 244, 0.15);

						.code_icon {
							width: 209px;
							height: 209px;
						}

						img {
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							width: 0.5rem;
							border-radius: 0.04rem;
						}
					}
				}
				.download_content{
					display: flex;
					align-items: center;
					justify-content: center;
                    margin-left: 1.2rem;
					max-width: 6.76rem;
                    @media(max-width: 880px) {
                        margin-left: 0.48rem;
                    }
                    @media (max-width: 768px) {
                        margin-left: 0;
                    }
					.download_list {
						display: grid;
						grid-template-columns: 1fr 1fr;
						grid-template-rows: 1fr;
						grid-column-gap: 0.6rem;
						grid-row-gap: 0.4rem;
						justify-content: center;
						align-items: center;
						@media (max-width: 768px) {
							margin-top: 0.4rem;
						}
						@media (max-width: 518px) {
							grid-template-columns: 1fr;
							grid-column-gap: 0;
							grid-row-gap: 0.48rem
						}
						.download_item {
							display: flex;
							flex-direction: column;
							align-items: center;

							.item_container {
								display: flex;
								justify-content: center;
								align-items: center;
								box-sizing: border-box;
								background: url("../assets/homePicture/downloadApp/buttonbg_regular.png") no-repeat center center;
								background-size: 100% 100%;
								width: 1.8rem;
								&:hover{
									background: url("../assets/homePicture/downloadApp/buttonbg_highlight.png")no-repeat center center;
									background-size: 100% 100%;
								}
								.item_content{
									display: flex;
									justify-content: center;
									align-items: center;
									padding: 0.12rem 0;
									.item_icon{
										font-size: 0.3rem;
										color:rgba(73, 118, 244, 1);
										margin-right: 0.16rem;
									}
									.item_text{
										.item_text_header{
											font-size: 0.14rem;
											font-weight: 400;
											color: rgba(255, 255, 255, 0.7);
											line-height: 0.14rem;
											margin-bottom: 0.04rem !important;
										}
										.item_text_content{
											font-size: 0.16rem;
											font-weight: bold;
											color: #FFFFFF;
											line-height: 0.16rem;
										}
									}
								}
							}
						}
					}

				}
			}
		}
	}
}
</style>