<template>
	<div id="app">
        <router-view></router-view>
	</div>
</template>

<script>
export default {
	name: 'App',
    mounted() {
        if(location.hash) {
            const hash = location.hash.split("#")[1];
            const top = document.getElementById(hash).offsetTop;
            window.scrollTo({
                smooth: true,
                top: top + 80
            })
        }
    },
}

</script>

<style lang="less" scoped>

#app {
	width: 100%;
    height: 100%;
}
</style>
