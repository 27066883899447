<template>
	<div class="header_container">
		<div class="header_wrap">
			<div class="header_content">
				<div class="super_wallet_logo" @click="toHome">
					<img src="../assets/homePicture/head/logo.png" alt="">
				</div>
				<div class="right_container">
					<ul class="nav_link">
						<li class="nav_item" :class="currentIndex === index ? 'nav_item_active' : ''" v-for="(item, index) in navList" :key="index" @click="(item.id && scrollToLink(item.id)), changeIndex(index)">
							<a v-if="item.id" class="nav_item_link" :href="`#${item.id}`" rel="noopener noreferrer">{{ item.navText }}</a>
							<a v-if="item.link" class="nav_item_link" :href="item.link" target="_blank" rel="noopener noreferrer">{{item.navText}}</a>
							<div class="line" v-if="currentIndex === index">
								<img src="../assets/homePicture/line.png" alt="">
							</div>
						</li>
					</ul>
                    <div class="select_btn" @click="openLangSubMenu">
                        <span class="default_lang">{{selectedLang.label}}</span>
                        <i class="iconfont icon-language" :class="isShowLangSubMenu ? 'animate' : ''"></i>
                        <ul v-if="isShowLangSubMenu" class="lang_list" :class="isShowLangSubMenu ? 'animation' : ''">
                            <li class="lang_item" v-for="(item, index) in langOptions" :key="index" 
                                :class="item.label === selectedLang.label ? 'lang_item_active' : ''"
                                @click="changeLang(item)"
                            >
                                {{item.label}}
                            </li>
                        </ul>
                    </div>
				</div>
			</div>
		</div>
        <div class="mobile_header_container">
            <div class="mobile_header_content">
                <div class="super_wallet_logo" @click="toHome">
					<img src="../assets/homePicture/head/logo.png" alt="">
				</div>
                <i class="iconfont icon-caidan" @click="openSubMenu"></i>
            </div>
            <div v-if="isShowSubMenu" class="mobile_sub_menu_container">
                <ul class="mobile_nav_list">
                    <li class="mobile_nav_item" :class="currentIndex === index ? 'mobile_nav_item_active' : ''" v-for="(nav, index) in navList" :key="index" @click="(nav.id && scrollToLink(nav.id)), changeIndex(index),closeSubMenu">
                        <a v-if="nav.id" class="mobile_nav_link" :href="`#${nav.id}`" rel="noopener noreferrer">{{ nav.navText }}</a>
                        <a v-if="nav.link" class="mobile_nav_link" :href="nav.link" target="_blank" rel="noopener noreferrer">{{nav.navText}}</a>
                    </li>
                </ul>
                <div class="mobile_select_btn" @click="openLangSubMenu">
                    <span class="mobile_default_lang">Language</span>
                    <i class="iconfont icon-language" :class="isShowLangSubMenu ? 'mobile_animate' : ''"></i>
                    <ul v-if="isShowLangSubMenu" class="mobile_lang_list" :class="isShowLangSubMenu ? 'mobile_animation' : ''">
                        <li class="mobile_lang_item" v-for="(item, index) in langOptions" :key="index" 
                            :class="item.label === selectedLang.label ? 'mobile_lang_item_active' : ''"
                            @click="changeLang(item)"
                        >
                            {{item.label}}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
export default {
	name: "asiadbHeader",
	data() {
		return {
            selectedLang: {label: "English", value: "en-US"},
            isShowLangSubMenu: false,
            langOptions: [
                {
                    label: "English",
                    value: "en-US"
                },
                {
                    label: "简体中文",
                    value: "zh-CN"
                },
                {
                    label: "繁體中文",
                    value: "zh-HK"
                },
            ],
            isShowSubMenu: false,
			offset: 80,
		}
	},
	computed: {
		currentIndex() {
			return this.$store.state.currentIndex;
		},
        navList() {
            return this.$t("langConfig.navList");
        }
	},
	methods: {
        toHome() {
          this.$router.push("/");
          document.getElementById("app").scrollTop = 0;
        },
        initLang() {
            let language = this.$route.query.lang || localStorage.getItem("currentLang") || navigator.language || "en-US";
            this.langOptions.forEach(item => {
                if(item.value === language) {
                    this.selectedLang.label = item.label;
                    this.selectedLang.value = item.value;
                }
            })
            this.$i18n.locale = language;
            localStorage.setItem("currentLang", language);
        },
        openLangSubMenu() {
            this.isShowLangSubMenu = !this.isShowLangSubMenu;
        },
        changeIndex(index) {
            if(index !== 3 && index !== 5) {
                this.$store.commit("currentIndex", index);
                localStorage.setItem("currentHash", `#${this.navList[index].id}`);
            }
        },
		scrollToLink(id) {
            if(id) {
                let el = document.getElementById(`${id}`);
                const top = el.offsetTop + this.offset;
                if(el) {
                    window.scrollTo({
                        smooth: true,
                        top
                    })
                }
            }
		},
        openSubMenu() {
            this.isShowSubMenu = !this.isShowSubMenu;
        },
        closeSubMenu() {
            this.isShowSubMenu = false;
        },
        changeLang(item) {
            this.selectedLang = item;
            this.$i18n.locale = item.value;
            localStorage.setItem("currentLang", item.value);
            if(this.$route.query.lang) {
                if(localStorage.getItem("currentHash")) {
                    this.$router.push({
                        path: this.$route.path,
                        query: {
                            lang: item.value
                        },
                        hash: localStorage.getItem("currentHash")
                    })
                } else {
                    this.$router.push({
                        path: this.$route.path,
                        query: {
                            lang: item.value
                        },
                    })
                }
            }
            this.isShowSubMenu = false;
        }
	},
    watch: {
        "$route.hash": {
            handler(newHash) {
                if(newHash) {
                    this.$nextTick(() => {
                        const hash = newHash.split("#")[1];
                        this.$nextTick(() => {
                            this.navList.forEach((item,index) => {
                                if(item.id === hash) {
                                    this.$store.commit("currentIndex", index);
                                }
                            })
                        })
                    })
                }else {
                    this.$store.commit("currentIndex", -1);
                }
            },
            immediate: true,
            deep: true
        },
    },
    mounted() {
		this.initLang();
    }
}
</script>

<style scoped lang="less">
.header_container {
	position: fixed;
	width: 100%;
	height: 0.8rem;
	background: #0D1734;
	z-index: 80;

	.header_wrap {
		margin: 0 auto;
		max-width: 12rem;
		height: 100%;
        @media (max-width: 970px) {
            display: none;
        }

		.header_content {
			display: flex;
			justify-content: space-between;
			align-items: center;
            max-width: 12rem;
			height: 100%;
			@media (max-width: 1050px) {
				padding: 0 0.48rem;
			}
			.super_wallet_logo {
				height: 0.32rem;
				cursor: pointer;
				img {
					height: 100%;
					vertical-align: center;
				}
			}
			.right_container {
				display: flex;
				justify-content: space-between;
				align-items: center;
				height: 100%;
				.nav_link {
					display: flex;
					align-items: center;
					margin-bottom: 0;
					height: 100%;
					.nav_item {
						position: relative;
						height: 100%;
						.nav_item_link {
							display: inline-block;
							padding: 0.29rem 0.24rem;
							width: 100%;
							font-size: 0.16rem;
							font-weight: 600;
							line-height: 0.22rem;
							color: rgba(255,255,255,0.5);
                            @media (max-width: 1200px) {
							    padding: 0.29rem 0.12rem;
                            }
						}
						.line {
							position: absolute;
							left: 50%;
							bottom: 0.11rem;
							transform: translateX(-50%);
							width: 1rem;
							height: 0.11rem;
							img {
								height: 100%;
								vertical-align: middle;
							}
						}
					}
                    .nav_item_active {
                        .nav_item_link {
                            color: #fff;
                        }
                    }
				}
                .select_btn {
                    display: flex;
                    align-items: center;
                    position: relative;
                    box-sizing: border-box;
                    margin-left: 1.2rem;
                    padding: 0.29rem 0.12rem;
                    cursor: pointer;
                    @media (max-width: 1200px) {
                        margin-left: 0.6rem;
                    }
                    @media (max-width: 1070px) {
                        margin-left: 0.2rem;
                    }
                    .default_lang {
                        font-size: 0.16rem;
                        font-weight: 600;
                        color: #FFFFFF;
                        line-height: 0.22rem;
                    }
                    .iconfont {
                        display: inline-block;
                        margin-left: 0.08rem;
                        font-size: 0.2rem;
                        color: #4976F4;
                        transition: transform .3s linear;
                    }
                    .animate {
                        transform: rotate(-180deg);
                        transition: transform .3s linear;
                    }
                    .lang_list {
                        position: absolute;
                        top: 0.8rem;
                        left: 0;
                        min-width: 1.2rem;
                        background: #fff;
                        border-radius: 0 0 0.04rem 0.04rem;
                        box-shadow: 0 0.02rem 0.16rem 0 rgba(0, 0, 0, 0.1);
                        .lang_item {
                            display: flex;
                            align-items: center;
                            box-sizing: border-box;
                            padding: 0.08rem 0.12rem;
                            font-size: 0.16rem;
                            font-weight: 400;
                            color: #FFFFFF;
                            background: #081025;
                        }
                        .lang_item_active {
                            color: #4976F4;
                        }
                    }
                }
			}
		}
	}
    .mobile_header_container {
        display: none;
        width: 100%;
        height: 100%;
        @media (max-width: 970px) {
            display: block;
        }
        .mobile_header_content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            padding: 0 0.48rem;
            width: 100%;
            height: 100%;
            @media (max-width: 400px) {
                padding: 0 0.16rem;
            }
            .super_wallet_logo {
				height: 0.32rem;
				cursor: pointer;
				img {
					height: 100%;
					vertical-align: center;
				}
			}
            .iconfont {
                font-size: 0.2rem;
                cursor: pointer;
            }
        }
        .mobile_sub_menu_container {
            width: 100%;
            background: #0D1734;
            .mobile_nav_list {
                width: 100%;
                .mobile_nav_item {
                    width: 100%;
                    .mobile_nav_link {
                        display: block;
                        box-sizing: border-box;
                        padding: 0.16rem 0.48rem;
                        width: 100%;
                        font-size: 0.16rem;
                        font-weight: 400;
                        color: #FFFFFF;
                        line-height: 0.16rem;
                        @media (max-width: 400px) {
                            padding: 0.16rem;
                        }
                    }
                }
                .mobile_nav_item_active {
                    .mobile_nav_link {
                        color: #4976F4;
                    }
                }
            }
            .mobile_select_btn {
                position: relative;
                display: flex;
                justify-content: space-between;
                box-sizing: border-box;
                padding: 0.16rem 0.48rem;
                width: 100%;
                cursor: pointer;
                @media (max-width: 400px) {
                    padding: 0.16rem;
                }
                .mobile_default_lang {
                    font-size: 0.16rem;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 0.16rem;
                }
                .iconfont {
                    color: #4976F4;
                    transition: transform .3s linear;
                }
                .mobile_animate {
                    transform: rotate(-180deg);
                    transition: transform .3s linear;
                }
                .mobile_lang_list {
                    position: absolute;
                    top: 0.48rem;
                    left: 0;
                    width: 100%;
                    background: #050E29;
                    .mobile_lang_item {
                        box-sizing: border-box;
                        padding: 0.16rem 0.48rem;
                        width: 100%;
                        @media (max-width: 400px) {
                            padding: 0.16rem;
                        }
                    }
                    .mobile_lang_item_active {
                        color: #4976F4;
                    }
                }
            }
        }
    }
}
</style>