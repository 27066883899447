<template>
	<div class="home_container">
		<AsiadbHeader></AsiadbHeader>
		<main class="main_container">
			<!-- <div class="seamless_scroll_content">
				<div class="seamless_scroll_wrap">
					<span class="iconfont icon-a-guangbogonggaolaba_jurassic"></span>
					<marquee-text :duration="15">
						<a class="support_link" :href="announcementHref" target="_blank" rel="noreferrer noopener">
							{{$t('langConfig.announcement')}}
						</a>
					</marquee-text>
				</div>
			</div> -->
			<Introduce></Introduce>
			<RegisterAccount></RegisterAccount>
			<DownloadApp></DownloadApp>
			<ContactUs></ContactUs>
		</main>
		<AsiadbFooter></AsiadbFooter>
	</div>
</template>

<script>
import AsiadbHeader from "@/components/asiadbHeader";
import AsiadbFooter from "@/components/asiadbFooter";
import Introduce from "@/components/Introduce";
import RegisterAccount from "@/components/RegisterAccount";
import DownloadApp from "@/components/downloadApp";
import ContactUs from "@/components/contactUs";
export default {
	name: "Home",

	components: {ContactUs, DownloadApp, RegisterAccount, Introduce, AsiadbFooter, AsiadbHeader},
	data() {
		return {
			announcementHref: this.$env.VUE_APP_SUPPORTURL
		}
	},

}
</script>

<style scoped lang="less">
.home_container {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	color: #ffffff;
	background: #0D1734;
	.main_container {
		flex: 1 0;
		width: 100%;
		.seamless_scroll_content{
			width: 100%;
			height: 0.52rem;
			overflow: hidden;
			padding-top: 0.8rem;
			background: rgba(255, 255, 255, 0.1);

			.seamless_scroll_wrap{
				width: 11.5rem;
				overflow: hidden;
				margin: 0 auto;
				display: flex;
				align-items: center;
				height: 100%;
				@media(max-width: 1200px){
					width: 100%;
					box-sizing: border-box;
					padding-left: 0.2rem;
					padding-right: 0.2rem;
				}
				.iconfont{
					margin-right: 0.16rem;
				}
				.support_link{
					font-size: 14px;
					font-weight: 600;
					color: #FFFFFF;
					line-height: 20px;
					white-space: nowrap;
					margin-right: 3rem;
				}
			}
		}
	}
}
</style>