<template>
	<div class="footer_container">
		<div class="footer_top_container">
			<div class="footer_top_content_container">
				<div class="footer_top_content">
					<ul class="first_group_list">
						<li class="first_item" v-for="(fItem, fIndex) in firstGroup" :key="fIndex">
							<a :href="fItem.link" class="iconfont" :class="fItem.icon" target="_blank"></a>
						</li>
					</ul>
					<ul class="second_group_list">
						<li class="second_item" v-for="(sItem, sIndex) in secondGroup" :key="sIndex">
							<a :href="sItem.link" class="iconfont" :class="sItem.icon" target="_blank"></a>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="footer_bottom_container">
			<div class="footer_bottom_content_container">
				<div class="footer_bottom_content">
					{{$t("langConfig.copyright")}}
				</div>
                <div class="protocol_wrap">
                    <a class="protocol_item" v-for="(item, index) in protocolList" :href="item.tip" :key="index" target="_blank">{{item.protocolName}}</a>
                </div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "asiadbFooter",
	data() {
		return {
			firstGroup: [
				{
					icon: "icon-facebook",
					link: "https://www.facebook.com/AsiaDigitalBank"
				},
				{
					icon: "icon-ins",
					link: "https://www.instagram.com/asiadigitalbank/"
				},
				{
					icon: "icon-twitter",
					link: "https://twitter.com/AsiaDigitalBank"
				},
				{
					icon: "icon-linkin",
					link: "https://www.linkedin.com/company/asia-digital-bank/?viewAsMember=true"
				},
				{
					icon: "icon-youtube",
					link: "https://www.youtube.com/channel/UCfelU3Z9EWvgQMEAhU9juAg"
				},
			],
			secondGroup: [
				{
					icon: "icon-telegram",
					link: "https://t.me/asiadigitalbank"
				},
				{
					icon: "icon-quora",
					link: "https://www.quora.com/profile/Asia-Digital-Bank"
				},
				{
					icon: "icon-Cari",
					link: "https://cn.cari.com.my/forum.php?mod=forumdisplay&fid=241"
				},
				{
					icon: "icon-reddit",
					link: "https://www.reddit.com/user/Asia_Digital_Bank"
				},
			],
		}
	},
    computed: {
        protocolList() {
            return this.$t("langConfig.protocolList");
        }
    },
}
</script>

<style scoped lang="less">
.footer_container {
	width: 100%;
    background: #0D1734;
	.footer_top_container {
		width: 100%;
		.footer_top_content_container {
			box-sizing: border-box;
			margin: 0 auto;
			padding: 0.3rem 0.48rem;
			max-width: 12rem;
			@media (max-width: 440px) {
				padding: 0.24rem 0.16rem;
			}
			.footer_top_content {
				display: flex;
				justify-content: center;
				align-items: center;
				@media (max-width: 530px) {
					flex-direction: column;
				}
				.first_group_list {
					display: flex;
					align-items: center;
					.first_item {
						display: flex;
						justify-content: center;
						align-items: center;
						margin-left: 0.16rem;
						width: 0.4rem;
						height: 0.4rem;
						background: rgba(73, 118, 244, 0.25);
						border-radius: 0.08rem;
						&:hover {
							background: #4976F4;
						}
						&:first-child {
							margin-left: 0;
						}
						.iconfont {
							font-size: 0.24rem;
							color: #FFFFFF;
						}
					}
				}
				.second_group_list {
					display: flex;
					align-items: center;
					.second_item {
						display: flex;
						justify-content: center;
						align-items: center;
						margin-left: 0.16rem;
						width: 0.4rem;
						height: 0.4rem;
						background: rgba(73, 118, 244, 0.25);
						border-radius: 0.08rem;
						@media (max-width: 530px) {
                            margin-top: 0.12rem;
							&:first-child {
								margin-left: 0;
							}
						}
						&:hover {
							background: #4976F4;
						}
						.iconfont {
							font-size: 0.24rem;
							color: #FFFFFF;
						}
					}
				}
			}
		}
	}
	.footer_bottom_container {
		width: 100%;
		border-top: 0.02rem solid rgba(73, 118, 244, 0.3);
		.footer_bottom_content_container {
            display: flex;
            justify-content: space-between;
            align-items: center;
			box-sizing: border-box;
			margin: 0 auto;
			padding: 0.18rem 0.48rem;
			max-width: 12rem;
            @media (max-width: 768px) {
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
			@media (max-width: 440px) {
				padding: 0.16rem 0.16rem;
			}
			.footer_bottom_content {
				font-size: 0.14rem;
				font-weight: 400;
				color: rgba(255, 255, 255, 0.69);
				line-height: 0.24rem;
                text-align: center;
			}
            .protocol_wrap {
                display: flex;
                justify-content: space-between;
                align-items: center;
                @media (max-width: 768px) {
                    margin-top: 0.12rem;
                }
                .protocol_item {
                    font-size: 0.14rem;
                    font-weight: 400;
                    color: #4976F4;
                    line-height: 0.14rem;
                    cursor: pointer;
                    &:nth-of-type(2) {
                        display: flex;
                        align-items: center;
                        &::before {
                            content: "";
                            display: block;
                            margin: 0 0.16rem;
                            width: 0.01rem;
                            height: 0.12rem;
                            background: rgba(255, 255, 255, 0.2);
                        }
                    }
                }
            }
		}
	}
}
</style>